import React from "react"

import { Layout } from "../components/layout"
import Seo from "../components/seo"
import { Controller, Scene } from 'react-scrollmagic'
import { BrowserView } from 'react-device-detect';

import fig404 from "../images/404.jpg";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="eyecatch">
      <div className="eyecatch__inner">
        <h1>
          <span>404</span>
        </h1>
      </div>
      <BrowserView>
        <Controller>
          <Scene
            classToggle={[".eyecatch__under", "enter"]} 
            triggerHook="onEnter"
            triggerElement=".enterContents"
            offset={300}
          >
            <div className="eyecatch__under">
              <div className={`mv__underRight`} />
            </div>
          </Scene>
        </Controller>
      </BrowserView>
    </div>
    <div className="error404__container enterContents">
      <div className="error404__containerBlock">
        <figure className="error404__containerBlockFig">
          <img src={fig404} alt="謙信" />
        </figure>
        <div className="error404__containerBlockBody">
          <h2>
            Sorry...<br />
            Page not found.
          </h2>
          <p>Please take a look at our cute dog instead.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
